import { EVENTS, EventType } from "../constants/events";
import normalizeDate from "./normalizeDate";

export default function getEvent(): EventType | null {
    const now: Date = new Date();
    for (const event of EVENTS) {
        const startDate: Date = normalizeDate(event.startDate);
        const endDate: Date = normalizeDate(event.endDate);
        if (now >= startDate && now <= endDate) return event.type;
    }
    return null;
}
