export default function HeroImageFrame() {
    return (
        <>
            <figure 
                className="absolute inset-x-[8%] inset-y-[15%] rounded-t-full 
                border-b-0 border-2 border-amber-500 border-opacity-90
                sm:inset-y-[8%] md:inset-y-[10%] xl:inset-y-[8%]"
            />
            <figure 
                className="absolute inset-x-[8%] inset-y-[15%] rounded-t-full 
                border-b-0 border-4 border-amber-300 filter blur-sm 
                animate-pulse sm:inset-y-[8%] md:inset-y-[10%] xl:inset-y-[8%]"
            />
        </>
    );
}
