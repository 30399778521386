import { Discount, DISCOUNTS } from "../constants/discounts";
import normalizeDate from "./normalizeDate";

export default function getDiscount(): Discount | null {
    const now: Date = new Date();
    for (const discount of DISCOUNTS) {
        const startDate: Date = normalizeDate(discount.startDate);
        const endDate: Date = normalizeDate(discount.endDate);
        if (now >= startDate && now <= endDate) return discount;
    }
    return null;
}
