import Translations from "./translations";

export interface Discount {
    name: string;
    discount: number;
    startDate: Date;
    endDate: Date;
};

export const DISCOUNTS: Discount[] = [
    {
        name: `${Translations.DISCOUNT}:new-year`,
        discount: 25,
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 0, 1)
    },
    {
        name: `${Translations.DISCOUNT}:easter`,
        discount: 10,
        startDate: new Date(new Date().getFullYear(), 3, 1),
        endDate: new Date(new Date().getFullYear(), 3, 30)
    },
    {
        name: `${Translations.DISCOUNT}:summer`,
        discount: 15,
        startDate: new Date(new Date().getFullYear(), 5, 1),
        endDate: new Date(new Date().getFullYear(), 7, 31)
    },
    {
        name: `${Translations.DISCOUNT}:winter`,
        discount: 10,
        startDate: new Date(new Date().getFullYear(), 11, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31)
    },
    {
        name: `${Translations.DISCOUNT}:christmas`,
        discount: 50,
        startDate: new Date(new Date().getFullYear(), 11, 24),
        endDate: new Date(new Date().getFullYear(), 11, 25)
    },
    {
        name: `${Translations.DISCOUNT}:valentine`,
        discount: 75,
        startDate: new Date(new Date().getFullYear(), 1, 14),
        endDate: new Date(new Date().getFullYear(), 1, 14)
    }
];
