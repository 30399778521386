"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function HeroImageButtonText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span
            className="opacity-90 text-xs font-semibold 
            text-amber-400 text-shadow-md group-hover:text-neutral-950
            group-hover:text-shadow-none dark:text-shadow-none
            sm:text-sm md:text-base lg:font-bold"
        >
            {t(`${Translations.HOME}:hero.image-button`)}
        </span>
    );
}
