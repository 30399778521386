import Link from "next/link";
import MicrophoneIcon from "@heroicons/react/24/outline/MicrophoneIcon";
import PaperClipIcon from "@heroicons/react/24/outline/PaperClipIcon";
import Routes from "@/app/constants/routes";
import HeroImageButtonText from "./HeroImageButtonText";

export default function HeroImageButton() {
    return (
        <>
            <Link
                href={Routes.LOGIN}
                className="group absolute z-30 bottom-0 left-0 right-0
                flex flex-row justify-between items-center h-[15%] p-3 
                rounded-3xl border-2 border-amber-500 border-opacity-90 
                bg-neutral-50 bg-opacity-50 sm:h-[8%] md:h-[10%] xl:h-[8%] 
                dark:bg-neutral-950 dark:bg-opacity-50 hover:justify-center
                hover:bg-amber-400 hover:border-none hover:scale-105 
                dark:hover:bg-amber-500 transition-all ease-in-out 
                duration-300 content-center"
            >
                <PaperClipIcon
                    className="h-4 w-4 my-auto opacity-90 text-amber-400
                    md:h-5 md:w-5 group-hover:hidden"
                />
                <HeroImageButtonText />
                <MicrophoneIcon
                    className="h-4 w-4 my-auto opacity-90 text-amber-400
                    md:h-5 md:w-5 group-hover:hidden"
                />
                <figure
                    className="absolute z-20 bottom-0 left-0 right-0 w-full 
                    h-full rounded-3xl border-4 border-amber-300 filter 
                    blur-sm animate-pulse group-hover:animate-none"
                />
            </Link>
        </>
    );
}
