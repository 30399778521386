"use client";

import ReactDOM from "react-dom";
import { getHeroImage } from "./HeroImage";

export function HeroImagePreload() {
    ReactDOM.preload(getHeroImage().src, {
        as: "image",
        fetchPriority: "high",
    });
    return null;
}
