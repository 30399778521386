export enum EventType {
    VALENTINE,
    EASTER,
    SUMMER,
    HALLOWEEN,
    WINTER,
    CHRISTMAS,
};

export interface Event {
    type: EventType;
    startDate: Date;
    endDate: Date;
};

export const EVENTS: Event[] = [
    {
        type: EventType.VALENTINE,
        startDate: new Date(new Date().getFullYear(), 1, 14),
        endDate: new Date(new Date().getFullYear(), 1, 14),
    },
    {
        type: EventType.EASTER,
        startDate: new Date(new Date().getFullYear(), 3, 1),
        endDate: new Date(new Date().getFullYear(), 3, 30),
    },
    {
        type: EventType.SUMMER,
        startDate: new Date(new Date().getFullYear(), 5, 1),
        endDate: new Date(new Date().getFullYear(), 7, 31),
    },
    {
        type: EventType.HALLOWEEN,
        startDate: new Date(new Date().getFullYear(), 9, 31),
        endDate: new Date(new Date().getFullYear(), 9, 31),
    },
    {
        type: EventType.WINTER,
        startDate: new Date(new Date().getFullYear(), 11, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
    },
    {
        type: EventType.CHRISTMAS,
        startDate: new Date(new Date().getFullYear(), 11, 25),
        endDate: new Date(new Date().getFullYear(), 11, 25),
    }
];
