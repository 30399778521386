import Image, { StaticImageData } from "next/image";
import HeroTemplateImage from "/public/images/hero-image.webp"
import HeroTemplateImageEaster from "/public/images/hero-image-easter.webp"
import HeroTemplateImageSummer from "/public/images/hero-image-summer.webp"
import HeroTemplateImageHalloween from "/public/images/hero-image-halloween.webp"
import HeroTemplateImageValentine from "/public/images/hero-image-valentine.webp"
import HeroTemplateImageWinter from "/public/images/hero-image-winter.webp"
import HeroTemplateImageChristmas from "/public/images/hero-image-christmas.webp"
import HeroImageButton from "./HeroImageButton";
import HeroImageFrame from "./HeroImageFrame";
import getEvent from "@/app/utils/getEvent";
import { EventType } from "@/app/constants/events";

export function getHeroImage(): StaticImageData {
    switch (getEvent()) {
        case EventType.EASTER:
            return HeroTemplateImageEaster;
        case EventType.SUMMER:
            return HeroTemplateImageSummer;
        case EventType.HALLOWEEN:
            return HeroTemplateImageHalloween;
        case EventType.VALENTINE:
            return HeroTemplateImageValentine;
        case EventType.WINTER:
            return HeroTemplateImageWinter;
        case EventType.CHRISTMAS:
            return HeroTemplateImageChristmas;
        default:
            return HeroTemplateImage;
    };
}

export default function HeroImage() {
    return (
        <figure className="relative w-1/2 sm:w-2/3 md:w-1/2 lg:w-1/3">
            { /* Frame around the hero image. */ }
            <HeroImageFrame />
            { /* The hero image. */ }
            <Image
                priority
                src={getHeroImage()}
                alt="Blonde girl with a black top and black pants"
                className="sticky z-10 w-full h-full"
                fetchPriority="high"
            />
            <figure
                className="absolute top-0 z-20 w-full h-full overflow-hidden"
            >
                <div 
                    className={
                        `absolute top-0 w-full h-[150%]
                        bg-[radial-gradient(ellipse_at_center,_transparent_70%,_theme(colors.neutral.50)_75%)]
                        dark:bg-[radial-gradient(ellipse_at_center,_transparent_70%,_theme(colors.neutral.950)_75%)]`
                    }
                />
            </figure>
            { /* The button at the bottom of the hero image. */}
            <HeroImageButton />
        </figure>
    );
}
